import React, { useState } from 'react';

function Chatbox() {
    const [isChatOpen, setChatOpen] = useState(false);
	const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    }

    const handleInputSubmit = (event) => {
		event.preventDefault();

		fetch('https://batesk.pythonanywhere.com/chat', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				message: userInput
		})
    })
        .then(response => response.json())
        .then(data => {
            setMessages(prevMessages => [...prevMessages, 
                { content: userInput, sender: 'User' }, 
                { content: data.response, sender: 'AI' }]);
            setUserInput('');
        });
	}

    return (
        <div>
            <div style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                width: '60px',
                height: '60px',
                borderRadius: '30px',
                backgroundColor: '#007bff',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={() => setChatOpen(!isChatOpen)}
            >
                Chat
            </div>
            {isChatOpen && (
                <div style={{
                    position: 'fixed',
                    bottom: '90px',
                    right: '20px',
                    width: '300px',
                    height: '400px',
                    background: 'rgb(128, 128, 128, .4)',
					backdropFilter:'blur(15px)',
                    borderRadius: '5px',
                    padding: '10px',
                }}>
                    {/* Place your chat content here */}
                    <div>
                        {messages.map((message, index) => (
                            <p key={index}><b>{message.sender}:</b> {message.content}</p>
                        ))}
                    </div>
                    <form onSubmit={handleInputSubmit}>
                        <input type="text" value={userInput} onChange={handleInputChange} />
                        <input type="submit" value="Send" />
                    </form>
                </div>
            )}
        </div>
    );
}

export default Chatbox;
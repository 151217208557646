import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import "../header.css";

function DesktopNavbar({ activeSection }) {
  return (
    <Nav className="ms-auto desktop-navbar">
        <Nav.Link onClick={() => activeSection('home')}>Home</Nav.Link>
        <Nav.Link onClick={() => activeSection('about')}>About</Nav.Link>
        <Nav.Link onClick={() => activeSection('projects')}>Projects</Nav.Link>
        <Nav.Link onClick={() => activeSection('contact')}>Contact</Nav.Link>
        <Button className="resumebtn" onClick={() => window.open('https://drive.google.com/file/d/1CMCcMDmg5egjY2UFWwy3X3XOo3n1uhiY/view?usp=sharing')}>
			Resume
		</Button>
    </Nav>
  );
}

export default DesktopNavbar;
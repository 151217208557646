//Libraries
import React, {Suspense, Component, useState, useEffect } from 'react';

//Components
import Header from './components/header/Header';
import Background from './components/background/Background';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Chatbox from './components/Chatbox';

//Styling
import './App.css';

class App extends Component {
  
  constructor() {
	  super();
	  this.state = {
	    activeSection: 'home',
	      backgroundImages: [
		    'Alley - Shinjuku.webp', 'Candle - Fushimi Taisha.webp', 
			'Cliff - Grand Canyon.webp', 'Clouds - Mesa.webp', 
			'Coor Hall - Tempe.webp', 'Crab - Dotonbori.webp',
			'Frames - Osaka.webp', 'Graffiti No. 1 - Daikanyama.webp',
			'Gaining Distance - Shinjuku.webp', 'Kabukiza - Ginza.webp',
			'Kodaiji - Nene no Michi.webp', 'More Graffiti - Mitaka.webp',
			'Namiyoke Inari Shrine - Tsukiji Fish Market.webp', 'Oinari-sama - Fushimi Taisha.webp',
			'Parking Garage - Tempe.webp', 'Ponytail - Hamonika Yokochou.webp',
			're- stacks - Arizona.webp', 'Red on Blue - Grand Canyon.webp',
			'Shadows - Grand Canyon.webp', 'Simple Geometry - Kyoto.webp', 
			'Statues - Nasu-Gun.webp', 'Torii - Fushimi Taisha.webp',
			'Two Roads - Fushimi Taisha.webp', 'Tyler and Haggeo - Postino.webp'
		  ],
		currentBackgroundIndex: Math.floor(Math.random() * 23),
		showAboutCard: false,
		isMobile: window.innerWidth <= 1024,
	  };
  }

  setActiveSection = (section) => {
    if (section === 'about') {
      if (this.state.activeSection === 'home') {
        this.setState({ showAboutCard: true });
      } else {
        this.setState({ activeSection: 'home', showAboutCard: true });
      }
    } else {
      this.setState({ activeSection: section, showAboutCard: false });
    }
  };

  cycleBackground = () => {
	const randomIndex = Math.floor(Math.random() * this.state.backgroundImages.length);
    this.setState({ currentBackgroundIndex: randomIndex });
  };

  handleResize() {
    const wasMobile = this.state.isMobile;
    const isMobile = window.innerWidth <= 667;

    if (wasMobile !== isMobile) {
      this.setState({
        isMobile: isMobile,
        currentBackgroundIndex: Math.floor(Math.random() * this.state.backgroundImages.length),
      });
    }
  }


componentDidMount() {
  if (!this.state.isMobile) {
    this.backgroundInterval = setInterval(this.cycleBackground, 20000);
  }
  window.addEventListener('resize', this.handleResize.bind(this));
}

componentWillUnmount() {
  if (this.backgroundInterval) {
    clearInterval(this.backgroundInterval);
  }
  window.removeEventListener('resize', this.handleResize.bind(this));
}

  renderContent = () => {
	const { activeSection, showAboutCard } = this.state;

	if (activeSection === 'home' || showAboutCard) {
	  return (
		<div className="flex-container">
		  <Home />
		  {showAboutCard && <About />}
		</div>
	  );
	} else if (activeSection === 'projects') {
	  return <Projects />;
	} else if (activeSection === 'contact') {
	  return <Contact />;
	}
  };

  render() {
    const directory = this.state.isMobile ? 'mobile/' : '';
	return (
		<div className="App">
	      <Suspense fallback={<div>Loading...</div>}>
            {this.state.backgroundImages.map((src, index) => (
                <Background
                  key={index}
                  src={`${directory}${src}`}
                  currentBackgroundIndex={this.state.currentBackgroundIndex}
                  index={index}
                  activeSection={this.state.activeSection}
				  isMobile={this.state.isMobile}
                />
            ))}
		  <div>
		    <header style={{position: "relative", zIndex: 2}}>
		      <Header activeSection={this.setActiveSection} />
		    </header>
			
		    <main style={{position: "relative", zIndex: 1}}>
			  {this.renderContent()}
		    </main>
		  </div>
		  <div>
			  <footer>
				/*<Chatbox />*/
			  </footer>
		  </div>
		</Suspense>
	  </div>
	);
  }
}

export default App;

import React from 'react';
import ContactForm from './ContactForm';

const Contact = () => {  
  return (
	<div style={{padding:"1px"}}>	  	  
	  <ContactForm /> 
	</div>
  );
};

export default Contact;
import React from 'react';
import { motion } from "framer-motion";
import './About.css';

const About = ({ activeSection }) => {
  return (  
    <div className='scroll-container'>
	<div className='about-container'>
	  <motion.div
	    key="about-card"
	    initial={{y: "-20%", opacity: 0,  backdropFilter: "blur(15px)"}}
        animate={{y: 0, opacity: 1, backdropFilter: "blur(15px)"}}
	    transition={{delay: .2, duration: 1.5, type: "spring", bounce: .35, ease: "easeOut"}}
		className="about-card"
	  >
        <h2>About</h2>
        <p>Hi there,</p>
		<p>I'm Kendall, a software developer with a background in computer science and philosophy. I thrive at the intersection of technical challenges and complex problem-solving, using my skills to create meaningful solutions.</p>
		<p>Through my diverse experience as a casting associate, I've fine-tuned my abilities in leadership, adaptability, and precision. My work often involves identifying problems before they become roadblocks, ensuring a smooth path for our projects.</p>
		<p>Though my academic background in computer science offers a strong foundation in key principles, I understand the importance of flexibility in the ever-evolving tech landscape. Thus, I consistently seek to expand my skills—mastering the latest web development frameworks or exploring emerging domains like machine learning.</p>
		<p>Software for me is more than just a tool that drives efficiency—it's a mirror that reflects our values and shapes our experiences. Bridging this view with practical application, I've collaborated with top talents in the film industry, leveraging technology to influence culture by optimizing creative processes and boosting productivity.</p>
		<p>When I'm not coding or problem-solving at work, I like to explore machine learning, produce music, stay active with weight training, and immerse myself in literature. I believe that maintaining a balanced life enhances creativity and productivity.</p>
		<p>If you're looking for a software developer who is ready to tackle technical challenges with a balanced and holistic approach, then I'd love to connect. </p>
		<p>Best,</p>
		<p>Kendall</p>
	  </motion.div>
    </div>
	</div>
  );
};

export default About;
import React from 'react';
import { motion } from "framer-motion";
import './SocialLinks.css';

const SocialLinks = () => {
  return (
    <div className="social-links" >
      <a href="https://www.imdb.com/name/nm14801252/?ref_=fn_al_nm_1" target="_blank" rel="noreferrer" className="social-link">
        <motion.div
	      initial={{ y:-25, opacity: 0 }}
	      animate={{ y: 0, opacity: 1 }}
	      transition={{ delay: .4, duration: .5, type: "spring", bounce: .5, ease: "easeOut" }}
		>
		  <i className="fa fa-imdb"></i>
		</motion.div>
      </a>
      <a href="https://github.com/bateskendall" target="_blank" rel="noreferrer" className="social-link">
        <motion.div
	      initial={{ y:-30, opacity: 0 }}
	      animate={{ y: 0, opacity: 1 }}
	      transition={{ delay: .475, duration: .5, type: "spring", bounce: .5, ease: "easeOut" }}
		>
   		  <i className="fa fa-github"></i>
		</motion.div>
      </a>
      <a href="https://open.spotify.com/artist/7bIXLmWU0mHVY3ViS3NkSZ?si=e4vF6DZkTWWKGJJRQt4TIw" target="_blank" rel="noreferrer" className="social-link">
        <motion.div
	      initial={{ y:-35, opacity: 0 }}
	      animate={{ y: 0, opacity: 1 }}
	      transition={{ delay: .55, duration: .5, type: "spring", bounce: .5, ease: "easeOut" }}
		>
		  <i className="fa fa-spotify"></i>
        </motion.div>
	  </a>
      <a href="https://www.instagram.com/k.bat.noise" target="_blank" rel="noreferrer" className="social-link">
		<motion.div
	      initial={{ y:-40, opacity: 0 }}
	      animate={{ y: 0, opacity: 1, stiffness: 10 }}
	      transition={{ delay: .625, duration: .5, type: "spring", bounce: .5, ease: "easeOut" }}
		  
		>
		  <i className="fa fa-instagram"></i>
        </motion.div>
	  </a>
    </div>
  );
};

export default SocialLinks;

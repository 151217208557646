import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { motion } from "framer-motion";
import axios from "axios";
import './Contact.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("/.netlify/functions/sendEmail", formData);
      console.log(response.data);
      alert("Thanks so much for your email! I will respond as soon as I am able.");
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error(error);
      alert("An error occurred while sending the email.");
    }
  };

  return (
	<div className="contact-container">
	  <Card>
        <motion.div
	      key="contact-card"
	      initial={{scale: 0.25, opacity: 0, backdropFilter: "blur(15)"}}
          animate={{scale: 1, opacity: 1, backdropFilter: "blur(15)"}}
	      transition={{delay: .2, duration: .4, type: "spring", bounce: .25, ease: "easeOut"}}
		  className="contact-card"
		>      
          <h2>Contact Me</h2>
          <Form onSubmit={handleSubmit} className="contact-form">
            <Form.Group controlId="contactForm.Name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="contactForm.Email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="contactForm.Subject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                as="textarea"
                name="subject"
				rows={1}
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="contactForm.Message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Send
            </Button>
          </Form>
	    </motion.div>
      </Card>
	</div>
  );
}

export default ContactForm;

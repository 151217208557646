import React from 'react';
import { Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import '../GitHubRepos.css';

const item = {
  hidden: { y: 20, opacity: 0, backdropFilter: "blur(15px)" },
  visible: { y: 0, opacity: 1, backdropFilter: "blur(15px)" },
};


const RepoCard = ({ repo }) => {
  const imageURL = repo.imageURL ? repo.imageURL : `https://raw.githubusercontent.com/${repo.owner.login}/${repo.name}/master/project-preview.webp`;
  

  
  return (
   	
	<motion.div
	  variants={item}
	  className="repo-card"
    >
      <Card className="card">
        <Card.Img variant="top" src={imageURL} className="card-image" alt="Repo thumbnail" />
        <Card.Body>
          <Card.Title style={{ fontSize: '2.25rem', fontWeight: 'bold', textAlign: 'center' }}>{repo.name}</Card.Title>
          <Card.Text className="text-fade-out">{repo.description}</Card.Text>
        </Card.Body>
      </Card>
	</motion.div>
  );
};

export default RepoCard;

import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { push as Menu } from 'react-burger-menu'
import "./MobileNavbar.css";

function MobileNavbar({ activeSection }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <Menu right isOpen={menuOpen} onStateChange={({ isOpen }) => setMenuOpen(isOpen)}>
      <Nav.Link className="mobileNav" onClick={() => { activeSection('home'); handleMenuClose(); }}>Home</Nav.Link>
      <Nav.Link className="mobileNav" onClick={() => { activeSection('about'); handleMenuClose(); }}>About</Nav.Link>
      <Nav.Link className="mobileNav" onClick={() => { activeSection('projects'); handleMenuClose(); }}>Projects</Nav.Link>
      <Nav.Link className="mobileNav" onClick={() => { activeSection('contact'); handleMenuClose(); }}>Contact</Nav.Link>
      <Nav.Link className="mobileNav" onClick={() => { window.open('https://drive.google.com/file/d/1CMCcMDmg5egjY2UFWwy3X3XOo3n1uhiY/view?usp=sharing'); handleMenuClose(); }}>
        Resume
      </Nav.Link>
    </Menu>
  );
}

export default MobileNavbar;
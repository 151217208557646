import React from 'react';
import './Background.css';

function Background({ src, currentBackgroundIndex, index, activeSection, isMobile }) {
  return (
    <div className={`background-container${currentBackgroundIndex === index ? ' show' : ''}`}>
      <img src={`${process.env.PUBLIC_URL}/backgrounds/${src}`} alt="background" className="background-image" />
      <div className="gradient-overlay" />
      <div className={`blur-overlay${(activeSection === 'projects' || activeSection === 'contact') && !isMobile ? " active" : ""}`}>
        <img src={`${process.env.PUBLIC_URL}/backgrounds/${src}`} alt="blurred background" className="blurred-background-image" />
      </div>
    </div>
  );
}

export default Background;
import React from 'react';
import { motion } from "framer-motion";
import 'font-awesome/css/font-awesome.min.css';
import "../App.css";

import SocialLinks from "./SocialLinks";

const Home = () => {
  return ( 
    <div className="hero-container">
	  <div className="hero">
		<motion.div
		  key="hero-name"
	      initial={{y: "30%", opacity: 0}}
		  animate={{y: 0, opacity: 1}}
		  transition={{duration: .75, type: "spring", bounce: .35, ease: "easeOut"}}
	    >
  	      <h1 className='hero-name'>Kendall Bates</h1>
		</motion.div>
		<motion.div
		  key="hero-description"
	      initial={{y: "30%", opacity: 0}}
		  animate={{y: 0, opacity: 1}}
		  transition={{delay: .2, duration: .75, type: "spring", bounce: .35, ease: "easeOut"}}
		>
	      <h2 className='hero-description'>Brooklyn-based Casting Pro &</h2>
          <h2 className='hero-description'>Freelance Web Engineer</h2>
  	    </motion.div>
	    <SocialLinks />
	  </div>
	</div>  
  );
};

export default Home;
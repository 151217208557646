import React from 'react';
import GitHubRepos from './GitHubRepos';
import './GitHubRepos.css';

const Projects = () => {  
  return (
	<div className="project-container" style={{padding:"5rem"}}>	  
	  <GitHubRepos /> 
	</div>
  );
};

export default Projects;
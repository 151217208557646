import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Navbar from 'react-bootstrap/Navbar';
import DesktopNavbar from './nav/DesktopNavbar';
import MobileNavbar from './nav/MobileNavbar';
import './header.css';

function Header({ activeSection }) {
  const isDesktop = useMediaQuery({minWidth: 768});
  
  return (
    <Navbar expand="md" fixed="top" className="navbar">
      <Navbar.Brand href="/" onClick={() => activeSection('home')}>
        <div className="logo">KB.</div>
      </Navbar.Brand>
      <div className="nav-container">
	    {isDesktop ? (
	      <DesktopNavbar activeSection={activeSection} />
	    ) : (
          <MobileNavbar activeSection={activeSection} />
	    )}
	  </div>
    </Navbar>
  );
}

export default Header;
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from "framer-motion";
import RepoCard from "./repos/RepoCard";
import './GitHubRepos.css';

function GitHubRepos() {
  const [repos, setRepos] = useState([]);
  
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delayChildren: 0.3, staggerChildren: 0.2},
    },
  };

  useEffect(() => {
    const fetchRepos = async () => {
      const response = await fetch('https://api.github.com/users/bateskendall/starred');
      const data = await response.json();
      setRepos(data);
    };

    fetchRepos();
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const itemsPerRow = windowWidth < 1200 ? 2 : 3;
  const repoChunks = chunkArray(repos, itemsPerRow);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function chunkArray(array, size) {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }

  return (
    <div>	
      <h2 className="projects-header">Projects</h2>
      <div className="repo-container">
        <Container>
          <motion.div
		    key={repos.length}
            className="container"
            variants={container}
            initial="hidden"
            animate="visible"
			exit={{x: -100, opacity: 0}}
          >
            {repoChunks.map((chunk, rowIndex) => (
              <Row key={rowIndex}>
                {chunk.map((repo, colIndex) => (
                  <Col key={colIndex} xs={12} md={6} lg={4} style={{ paddingBottom: "1em" }}>
				    <a href={repo.html_url} className="repo-card-link" target="_blank" rel="noreferrer">
					  <motion.div initial={{y:0}} transition={{type: "spring", bounce: .65}} whileHover={{y: -10}}>
  					    <RepoCard repo={repo} />
					  </motion.div>
					</a>
                  </Col>
                ))}
              </Row>
            ))}
          </motion.div>
        </Container>
      </div>
    </div>
  );
}

export default GitHubRepos;
